<template>
  <!-- <ValidationObserver tag="form" ref="obs1" @submit.prevent="submit()"> -->
  <ValidationObserver
    tag="form"
    ref="didCampaignServiceRef"
    @submit.prevent="submit()"
    v-slot="{ invalid }"
  >
    <v-row>
      <v-col :cols="12" md="6" class="ma-0">
        <!-- <v-row class="px-5 mb-5 col-6 col"> -->
        <v-col :cols="12" class="pb-0">
          <h3 class="subtitle-1 font-weight-bold">
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
            캠페인 이름
          </h3>
          <v-col :cols="12" class="pa-0">
            <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
              <CustomInput
                v-model="didCampaignService.name"
                required
                :errorMessages="errors"
                :hideDetails="!errors.length"
                placeholder="캠페인 이름"
                focusable
              />
            </ValidationProvider>
          </v-col>
        </v-col>

        <v-col :cols="12" class="pb-0">
          <h3 class="subtitle-1 font-weight-bold">
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
            광고주 계정
          </h3>
          <v-col :cols="12" class="pa-0">
            <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
              <v-autocomplete
                v-model="didCampaignService.advertiserId"
                :items="advertisers"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                hide-no-data
                :hide-details="!errors.length"
                placeholder="광고주 계정을 선택해 주세요"
                outlined
                dense
                @change="handleAdvertiserId"
              />
            </ValidationProvider>
          </v-col>
        </v-col>

        <v-col :cols="12" class="pb-0">
          <h3 class="subtitle-1 font-weight-bold">
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
            노출 매체 그룹
          </h3>
          <v-col :cols="12" class="pa-0">
            <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
              <v-autocomplete
                v-model="didCampaignService.didMediaGroupId"
                outlined
                hide-no-data
                multiple
                clearable
                placeholder="광고를 노출할 매체그룹을 선택해 주세요"
                :items="didMediaGroups"
                item-text="name"
                item-value="id"
                dense
                :disabled="dialogLoading"
                :error-messages="errors"
                :hide-details="!errors.length"
                @change="handleMediaGroupId"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span
                      v-if="
                        didCampaignService.didMediaGroupId.length ===
                        didMediaGroups.length
                      "
                    >
                      전체선택
                    </span>
                    <span v-else>
                      {{ item.name }}
                      {{
                        didCampaignService.didMediaGroupId.length > 1
                          ? ` 외 ${
                              didCampaignService.didMediaGroupId.length - 1
                            } 개`
                          : ''
                      }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip
                    v-if="item.did_media_group_name"
                    label
                    small
                    class="mr-2"
                    color="secondary"
                    outlined
                  >
                    <!-- 매체사 그룹 이름 -->
                    {{ item.did_media_group_name }}
                  </v-chip>
                  {{ item.name }}
                  <span class="ml-2 caption">
                    ({{ item.type === 'D' ? '직매채' : '대행사' }})
                  </span>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleDidMediaGroupIds">
                    <v-list-item-content>
                      <v-list-item-title> 전체선택 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-col>

        <v-col :cols="12" class="pb-0">
          <h3 class="subtitle-1 font-weight-bold">
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
            노출 매체
          </h3>
          <v-col :cols="12" class="pa-0">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{
                required:
                  !!didCampaignService.didMediaGroupId &&
                  didCampaignService.didMediaGroupId.length > 0
              }"
            >
              <v-autocomplete
                v-model="didCampaignService.didBranchIds"
                outlined
                hide-no-data
                multiple
                clearable
                placeholder="광고를 노출할 매체을 선택해 주세요"
                :items="filteredDidBranches"
                item-text="name"
                item-value="id"
                dense
                :disabled="dialogLoading || !didCampaignService.didMediaGroupId"
                :error-messages="errors"
                :hide-details="!errors.length"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span
                      v-if="
                        didCampaignService.didBranchIds.length ===
                        didBranches.length
                      "
                    >
                      전체선택
                    </span>
                    <span v-else>
                      {{ item.name }}
                      {{
                        didCampaignService.didBranchIds.length > 1
                          ? ` 외 ${
                              didCampaignService.didBranchIds.length - 1
                            } 개`
                          : ''
                      }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip
                    v-if="item.did_media_group_name"
                    label
                    small
                    class="mr-2"
                    color="secondary"
                    outlined
                  >
                    <!-- 매체사 그룹 이름 -->
                    {{ item.did_media_group_name }}
                  </v-chip>
                  {{ item.name }}
                </template>

                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleDidBranchIds">
                    <v-list-item-content>
                      <v-list-item-title> 전체선택 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-col>
      </v-col>
      <v-col :cols="12" md="6" class="row ma-0 flex-column">
        <!-- 선택한 셋톱박스 목록 -->
        <ValidationProvider :rules="{ required: true, min_value: 1 }">
          <input hidden v-model="selectedSettopBoxCount" />
        </ValidationProvider>

        <v-col cols="auto" class="pl-5" style="height: auto">
          <h3 class="row ma-0 subtitle-2 font-weight-bold">
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
            사용가능 셋톱 박스 ( 선택: {{ selectedSettopBoxCount }} )

            <v-spacer />
            <!-- <v-chip
            outlined
            small
            :color="
              filteredSettopBoxes.length === didCampaignService.settopBoxes
                ? '#00d1b2'
                : 'secondary'
            "
          > -->
            <v-chip
              outlined
              small
              :color="
                filteredSettopBoxes.length === selectedSettopBoxCount
                  ? '#00d1b2'
                  : 'secondary'
              "
              @click.stop="() => selectedAllSettopBox()"
            >
              전체
              {{
                filteredSettopBoxes.length === selectedSettopBoxCount
                  ? '해제'
                  : '선택'
              }}
            </v-chip>
          </h3>
        </v-col>
        <v-col
          v-if="filteredSettopBoxes.length > 0"
          style="max-height: 550px; min-height: 500px; overflow: auto"
          class="invalid_settop_box"
        >
          <div>
            <a-table
              class="px-3 table-striped-rows"
              :columns="columns"
              :data-source="filteredSettopBoxes"
              size="small"
              :locale="{
                emptyText: '데이터가 없습니다.'
              }"
              bordered
              rowKey="id"
              :row-selection="{
                selectedRowKeys: didCampaignService.didSettopBoxIds,
                onChange: selected => onSelectChange(selected)
              }"
              :pagination="{ pageSize: 10 }"
              :scroll="{ x: true }"
            >
              <template slot="settopBoxCostPerDay" slot-scope="value">
                <div class="text-right subtitle-body font-weight-600 red--text">
                  {{ (value * 1).toLocaleString() }}
                </div>
              </template>
            </a-table>
          </div>
        </v-col>
        <v-col
          v-else
          class="row align-center justify-center ma-0"
          style="border: 1px solid #ddd"
        >
          <h4 class="font-weight-bold text-center">
            노출 매체를 선택해 주세요.
          </h4>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <DidCampaignServiceRegisterFooter :invalid="invalid" />
    </v-row>
  </ValidationObserver>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomInput from '@/components/common/CustomInput.vue';

import { getUserCampaignUserApi } from '@/api/admin/UserAPI';
import { getDidBranchOptionsApi } from '@/api/admin/DidBranchAPI';
import { getDidMediaGroupOptionsApi } from '@/api/admin/DidMediaGroupAPI';
import { getDidSettopBoxOptionsApi } from '@/api/admin/DidSettopBoxAPI';

import DidCampaignServiceRegisterFooter from './DidCampaignServiceRegisterFooter.vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomInput,
    DidCampaignServiceRegisterFooter
  },
  computed: {
    ...mapGetters({
      currentStep: 'didCampaignService/currentStep',
      dialog: 'didCampaignService/dialog',
      dialogLoading: 'didCampaignService/dialogLoading',
      isDialogTypeEdit: 'didCampaignService/isDialogTypeEdit',
      didCampaignService: 'didCampaignService/didCampaignService',
      campaignSteps: 'didCampaignService/didCampaignServiceSteps',
      invalidDialog: 'didCampaignService/invalidDialog'
    }),
    allDidBranches() {
      return (
        this.didCampaignService.didBranchIds.length ===
        this.filteredDidBranches.length
      );
    },
    allDidMediaGroups() {
      return (
        this.didCampaignService.didMediaGroupId?.length ===
        this.didMediaGroups.length
      );
    },
    filteredDidBranches() {
      return (
        this.$refs.networkAC?.filteredItems ||
        this.filteredByMediaGroupDidBranches
      );
    },
    filteredByMediaGroupDidBranches() {
      return this.didBranches.filter(item =>
        this.didCampaignService.didMediaGroupId
          ? this.didCampaignService.didMediaGroupId?.includes(
              item.did_media_group_id
            )
          : item
      );
    },
    filteredSettopBoxes() {
      if (!this.didCampaignService.didBranchIds.length) return [];

      return this.didSettopBoxes.filter(item =>
        this.didCampaignService.didBranchIds
          ? this.didCampaignService.didBranchIds?.includes(item.did_branch_id)
          : item
      );
    },
    // 사용가능 셋톱박스 테이블 columns
    columns() {
      return [
        {
          title: '지점 이름',
          dataIndex: 'did_branch_name',
          key: 'did_branch_name',
          align: 'center',
          sorter: (a, b) => a.did_branch_name.localeCompare(b.did_branch_name)
        },
        {
          title: '이름',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          sorter: (a, b) => a.name.localeCompare(b.name)
        }
      ];
    },
    selectedSettopBoxCount() {
      return this.didCampaignService.didSettopBoxIds.length;
    }
  },
  // watch: {
  //   dialog: function (newVal) {
  //     if (newVal) {
  //       this.$refs.didCampaignServiceRef.reset();
  //     }
  //   }
  // },
  data() {
    return {
      advertisers: [],
      didMediaGroups: [],
      didBranches: [],
      didSettopBoxes: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'didCampaignService/setCurrentStep'
    }),
    async init() {
      await Promise.all([
        this.fetchAdvertisers(),
        this.fetchDidMediaGroupOptions(),
        this.fetchDidBranchOptions(),
        this.fetchDidSettopBoxOptions()
      ]);

      if (this.$refs.didCampaignServiceRef) {
        this.$refs.didCampaignServiceRef.reset();
      }
    },

    /**@description: 광고주 목록 */
    async fetchAdvertisers() {
      const [error, data] = await getUserCampaignUserApi();
      if (error) {
        console.error(error);
      } else {
        const { advertisers } = data;
        this.advertisers = advertisers.map(item => ({
          id: item.id,
          user_id: item.user_id,
          name: item.name
        }));
      }
    },
    /** 매체 그룹 콤보 박스 목록 조회 */
    async fetchDidMediaGroupOptions() {
      this.didMediaGroups = [];
      const [error, data] = await getDidMediaGroupOptionsApi({
        params: { isActive: false }
      });
      if (error) {
        console.log(error);
      } else {
        const { did_media_groups: didMediaGroups } = data;
        this.didMediaGroups = didMediaGroups;
      }
    },
    /** 매체 콤보 박스 목록 조회 */
    async fetchDidBranchOptions() {
      this.didBranches = [];
      const [error, data] = await getDidBranchOptionsApi();
      if (error) {
        console.log(error);
      } else {
        const { branches } = data;
        this.didBranches = branches;
      }
    },
    /** 셋톱박스 콤보 박스 목록 조회 */
    async fetchDidSettopBoxOptions() {
      this.didSettopBoxes = [];
      const [error, data] = await getDidSettopBoxOptionsApi();
      if (error) {
        console.log(error);
      } else {
        const { settop_boxes } = data;
        this.didSettopBoxes = settop_boxes;
      }
    },
    /** @description: 다음으로 넘어가기 전  validate  */
    async submit() {
      const valid = await this.$refs.didCampaignServiceRef.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    },
    /** @description: 매체 선택 변경시 */
    toggleDidBranchIds() {
      this.$nextTick(() => {
        if (this.allDidBranches) {
          this.didCampaignService.didBranchIds = [];
        } else {
          this.didCampaignService.didBranchIds = this.filteredDidBranches
            .slice()
            .map(v => v.id);
        }
        this.didCampaignService.didBranchs =
          this.filteredByMediaGroupDidBranches.filter(item =>
            this.didCampaignService.didBranchIds.includes(item.id)
          );
      });
    },
    /** @description: 매체 그룹 선택 변경시 */
    toggleDidMediaGroupIds() {
      this.$nextTick(() => {
        let tempDidMediaGroupId = [];
        if (!this.allDidMediaGroups) {
          tempDidMediaGroupId = this.didMediaGroups.map(item => item.id);
        }

        this.$set(
          this.didCampaignService,
          'didMediaGroupId',
          tempDidMediaGroupId
        );

        if (!tempDidMediaGroupId.length) {
          this.didCampaignService.didSettopBoxIds = [];
          this.didCampaignService.didBranchIds = [];
        }
      });
    },
    /** @description: 광고주 선택 변경시 */
    handleAdvertiserId(val) {
      this.didCampaignService.advertiser = this.advertisers.find(
        item => item.id === val
      );
      this.didCampaignService.campaignResourceId = null;
      this.didCampaignService.campaignResource = null;
    },
    handleMediaGroupId() {
      this.didCampaignService.didBranchIds = [];
      // this.fetchDidMediaGroupOptions();
    },
    /**@description: 셋톱박스 선택했을 경우 */
    onSelectChange(selectedRowKeys) {
      // 사용가능 셋톱박스 목록에 있는 index 로 선택한 ID값 세팅

      this.didCampaignService.didSettopBoxIds = [...selectedRowKeys];
      // this.filteredSettopBoxes[index].list.selectedIds = [
      //   ...selectedRowKeys
      // ];
    },
    selectedAllSettopBox() {
      if (this.selectedSettopBoxCount === this.filteredSettopBoxes.length) {
        this.didCampaignService.didSettopBoxIds = [];
      } else {
        this.didCampaignService.didSettopBoxIds = [
          ...this.filteredSettopBoxes.map(item => item.id)
        ];
      }
    }
  }
};
</script>
