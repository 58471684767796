<template>
  <div class="d-inline-block float-right">
    <v-dialog v-model="reviewDialog" persistent max-width="1600px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="invalid"
          color="secondary"
          large
        >
          등록
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">캠페인 등록</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-container class="px-5">
                <v-row>
                  <v-col cols="12">
                    <h3 class="subtitle-2 font-weight-bold">
                      <v-icon color="error" x-small>mdi-asterisk</v-icon>
                      캠페인 이름
                    </h3>
                    <v-text-field
                      :value="didCampaignService.name"
                      outlined
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12">
                    <h3 class="subtitle-2 font-weight-bold">
                      <v-icon color="error" x-small>mdi-asterisk</v-icon>
                      광고주 계정
                    </h3>
                    <v-text-field
                      :value="didCampaignService.advertiser.name"
                      outlined
                      readonly
                      hide-details
                    />
                  </v-col>
                </v-row>

                <v-row class="mt-0" v-if="didCampaignService.campaignResource">
                  <v-col cols="12">
                    <h3 class="subtitle-2 font-weight-bold">
                      <v-icon color="error" x-small>mdi-asterisk</v-icon>
                      소재
                    </h3>

                    <video
                      :src="didCampaignService.campaignResource.url"
                      controls
                      class="mx-auto col-12"
                    />
                    <v-col
                      cols="12"
                      class="subtitle-2 font-weight-bold px-3 pt-0"
                    >
                      - 이름: {{ didCampaignService.campaignResource.name
                      }}<br />
                      - 해상도:
                      {{ didCampaignService.campaignResource.width }} X
                      {{ didCampaignService.campaignResource.height }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-divider vertical />

            <v-col cols="12" lg="6">
              <v-container class="px-5">
                <h3 class="subtitle-2 font-weight-bold">
                  <v-icon color="error" x-small>mdi-asterisk</v-icon>
                  송출 내역 ( 선택: {{ selectedSettopBoxesCount }} )
                </h3>
                <v-row class="ma-0">
                  <v-col
                    style="
                      max-height: 550px;
                      overflow: auto;
                      border: 1px solid #e0e0e0;
                    "
                  >
                    <v-card
                      v-for="(detail, index) in didCampaignService.didCampaigns"
                      :key="index"
                      :outlined="index % 2 === 1 ? false : true"
                      :style="`background: ${index % 2 === 1 ? '#E0E0E0' : ''}`"
                    >
                      <v-card-text class="subtitle-2 font-weight-bold py-3">
                        <v-row class="ma-0" align="center">
                          <v-col class="pa-0">
                            <v-chip label class="mr-3">
                              <v-icon left> mdi-calendar </v-icon>
                              {{ detail.scheduleDate }}
                            </v-chip>
                            <span class="pl-3 text-caption"> 매체 : </span>
                            <span class="px-2">
                              {{ detail.didBranchName }}
                            </span>

                            <v-divider
                              vertical
                              class="px-0 mx-3 col-12"
                              style="width: 1px"
                            />
                            <span class="pl-3 text-caption"> 셋톱박스 : </span>

                            <span class="px-2">
                              {{ detail.settopBoxName }}
                            </span>
                          </v-col>

                          <!-- <v-col cols="auto" class="pa-0">
                            <v-icon left @click="handleDeleteClick(index)">
                              mdi-close
                            </v-icon>
                          </v-col> -->
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                  <v-col cols="auto">
                    <h3 class="pl-3 subtitle-1 font-weight-bold text-right">
                      총 결제금액
                    </h3>
                  </v-col>
                  <v-col>
                    <h3 class="pr-3 title text-right">
                      {{
                        didCampaignService.totalPoint
                          ? didCampaignService.totalPoint.toLocaleString()
                          : 0
                      }}
                      원
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="reviewDialog = loading = false"
            :disabled="loading"
            large
          >
            이전
          </v-btn>
          <v-btn
            color="secondary"
            @click="handleCompleteClick()"
            :loading="loading"
            :disable="loading"
            large
          >
            완료
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { createDidCampaignServiceApi } from '@/api/admin/DidCampaignAPI';

export default {
  components: {},
  props: ['invalid'],
  computed: {
    ...mapGetters({
      didCampaignService: 'didCampaignService/didCampaignService'
    }),
    selectedSettopBoxesCount() {
      return this.didCampaignService.didCampaigns.length;
    }
  },
  data() {
    return {
      reviewDialog: false,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      closeDialog: 'didCampaignService/closeDialog'
    }),
    handleDeleteClick(index) {
      this.didCampaignService.didCampaigns.splice(index, 1);
    },
    async createCampaign() {
      this.loading = true;
      // 캠페인 등록
      const [error] = await createDidCampaignServiceApi(
        this.didCampaignService.getCreatePayload()
      );

      if (error) {
        console.error(error);
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        this.$root.$emit('getDidCampaignServicesApiEvent');
        this.$Swal
          .fire({
            icon: 'success',
            html: `캠페인이 등록 되었습니다.`,
            confirmButtonText: '확인'
          })
          .then(() => this.closeDialog());
      }
      this.loading = false;
    },
    handleCompleteClick() {
      this.createCampaign();
    }
  }
};
</script>
